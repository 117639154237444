<template>
  <div class="pt-10">
    <div class="mb-10">
      <h3 class="fw-400">Transport Costs</h3>
    </div>

    <Table :columns="columns" ref="table" :action="actions.SEARCH_SAMPLE_TRANSPORT_COSTS" :id="Number(props.id)">


      <template v-slot:extra="slotProps">

        <div class="mb-5">

            <AddSampleTransportCost :id="props.id" @input="slotProps.search()"/>

        </div>

      </template>

      <template v-slot:cost="slotProps">
        <div>{{ numeral(slotProps.row.cost).format('0,0.00000') }}</div>
      </template>

      <template v-slot:actions="slotProps">

        <div>

          <div class="btn-group" role="group">

            <EditSampleTransportCost :transportcost="slotProps.row" @input="slotProps.search()"/>

            <DeleteRecord :id="slotProps.row.id" :action="actions.DELETE_SAMPLE_TRANSPORT_COST" :callback="slotProps.search"/>

          </div>

        </div>

      </template>

    </Table>

  </div>
</template>
<script lang="ts">
import { Actions } from "@/store/enums/SampleTransportCostsEnums.ts";
import { Actions as LocationActions } from "@/store/enums/LocationEnums.ts";
import Table from '@/components/custom/Table.vue';
import DeleteRecord from '@/components/custom/DeleteRecord.vue';
import AddSampleTransportCost from "@/views/samples/edit/transport_costs/AddSampleTransportCost.vue";
import EditSampleTransportCost from "@/views/samples/edit/transport_costs/EditSampleTransportCost.vue";
import { useStore } from 'vuex'
import { computed, ref, reactive, onMounted } from 'vue'
import numeral from 'numeral'
export default {
  name : 'TransportCosts',
  props : ["id"],
  components : {
    Table,
    DeleteRecord,
    AddSampleTransportCost,
    EditSampleTransportCost
  },
  setup(props){

    const store = useStore();

    const actions = computed(() => {
      return Actions;
    })

    onMounted(() => {
      store.dispatch(LocationActions.GET_LOCATIONS);
    })

    const columns = [
      {name : 'location', title : 'Location', sortable : true, sort : 'asc', searchable : true},
      {name : 'cost', title : 'Cost per Carton', sortable : true, sort : null, searchable : true},
      {name : 'actions', title : 'Edit / Delete',sortable : false, sort : null, searchable : false}
    ];

    return {
      actions,
      props,
      columns,
      store,
      numeral
    }
  }
}
</script>
