<template>
  <div>
    <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
      <!--begin::Card header-->
      <div class="card-header cursor-pointer">
        <!--begin::Card title-->
        <div class="card-title m-0">
          <h3 class="fw-bolder m-0">Edit Sample</h3>
        </div>
        <!--end::Card title-->
      </div>

      <!--begin::Card body-->
      <div class="card-body p-9">

        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="details-tab" data-bs-toggle="tab" data-bs-target="#details" type="button" role="tab" aria-controls="details" aria-selected="true">Details</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="bom-tab" data-bs-toggle="tab" data-bs-target="#bom" type="button" role="tab" aria-controls="bom" aria-selected="false">BOM</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="transport-costs-tab" data-bs-toggle="tab" data-bs-target="#transport-costs" type="button" role="tab" aria-controls="transport-costs" aria-selected="false">Transport Costs</button>
          </li>
          <!-- <li class="nav-item" role="presentation">
            <button class="nav-link" id="agreed-prices-tab" data-bs-toggle="tab" data-bs-target="#agreed-prices" type="button" role="tab" aria-controls="agreed-prices" aria-selected="false">Agreed Prices</button>
          </li> -->
        </ul>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active" id="details" role="tabpanel" aria-labelledby="details-tab">
            <SampleDetails :id="route.params.id" />
          </div>
          <div class="tab-pane fade" id="bom" role="tabpanel" aria-labelledby="bom-tab">
            <SampleBOM :id="route.params.id" />
          </div>
          <div class="tab-pane fade" id="transport-costs" role="tabpanel" aria-labelledby="transport-costs-tab">
            <SampleTransportCosts :id="route.params.id" />
          </div>
          <!-- <div class="tab-pane fade" id="agreed-prices" role="tabpanel" aria-labelledby="agreed-prices-tab">...</div> -->
        </div>

      </div>

    </div>
  </div>
</template>
<script lang="ts">
import { onMounted } from 'vue';
import SampleDetails from '@/views/samples/edit/SampleDetails.vue'
import SampleBOM from '@/views/samples/edit/SampleBOM.vue'
import SampleTransportCosts from '@/views/samples/edit/SampleTransportCosts.vue'
import { useRoute } from 'vue-router';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
export default {
  name: 'CompositeEdit',
  components : {
    SampleDetails,
    SampleBOM,
    SampleTransportCosts,
  },
  setup(){
    const route = useRoute();

    onMounted(() => {
      setCurrentPageBreadcrumbs("Samples",[
        {title : "Samples",to : "/samples/list"},
        {title : "Edit Sample",to : "/samples/edit/"+route.params.id}
      ]);
    });

    return {
      route
    }
  }
}
</script>
