
import { Actions } from "@/store/enums/SampleBOMEnums";
import { Actions as SampleActions } from "@/store/enums/SamplesEnums";
import { Actions as ProductActions } from "@/store/enums/ProductEnums";
import Table from '@/components/custom/Table.vue';
import DeleteRecord from '@/components/custom/DeleteRecord.vue';
import AddSampleBOM from "@/views/samples/edit/bom/AddSampleBOM.vue";
import EditSampleBOM from "@/views/samples/edit/bom/EditSampleBOM.vue";
import { useStore } from 'vuex'
import { computed, ref, reactive, onMounted } from 'vue'
import numeral from 'numeral'
export default {
  name : 'CompositeBOM',
  props : ["id"],
  components : {
    Table,
    DeleteRecord,
    AddSampleBOM,
    EditSampleBOM
  },
  setup(props){

    const store = useStore();

    const success = ref(false)

    const errors = reactive({
      value : {}
    });

    onMounted(() => {
      store.dispatch(SampleActions.GET_SAMPLE ,{id : props.id});
      store.dispatch(ProductActions.GET_ALL_COMPONENTS,{type : 'component'});
    });

    const actions = computed(() => {
      return Actions;
    })

    const submitButton = ref<HTMLElement | null>(null);



    const submitForm = () => {

      if(submitButton.value){
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      store.dispatch(Actions.ADD_SAMPLE_BOM_COMMENT,{bomComment : store.getters.currentSample.bomComment, id : props.id}).then(() => {

        success.value = true;
        errors.value = [];
        submitButton.value?.removeAttribute("data-kt-indicator");
        window.scrollTo(0,0);

      }).catch((response) => {
        setTimeout(function(){
          const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
          input.focus();
        },100)

        errors.value = response.data.errors;

        submitButton.value?.removeAttribute("data-kt-indicator");

      })
    }


    const columns = [
      {name : 'code', title : 'Product Code', sortable : true, sort : 'asc', searchable : true},
      {name : 'name', title : 'Product Name', sortable : true, sort : null, searchable : true},
      {name : 'quantity', title : 'Quantity', sortable : true, sort : null, searchable : true},
      {name : 'bomCost', title : 'Cost Price', sortable : true, sort : null, searchable : true},
      {name : 'actions', title : 'Edit / Delete',sortable : false, sort : null, searchable : false}
    ];

    return {
      actions,
      props,
      columns,
      store,
      submitForm,
      success,
      errors,
      numeral
    }
  }
}
