
import { onMounted } from 'vue';
import SampleDetails from '@/views/samples/edit/SampleDetails.vue'
import SampleBOM from '@/views/samples/edit/SampleBOM.vue'
import SampleTransportCosts from '@/views/samples/edit/SampleTransportCosts.vue'
import { useRoute } from 'vue-router';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
export default {
  name: 'CompositeEdit',
  components : {
    SampleDetails,
    SampleBOM,
    SampleTransportCosts,
  },
  setup(){
    const route = useRoute();

    onMounted(() => {
      setCurrentPageBreadcrumbs("Samples",[
        {title : "Samples",to : "/samples/list"},
        {title : "Edit Sample",to : "/samples/edit/"+route.params.id}
      ]);
    });

    return {
      route
    }
  }
}
