<template>
  <div>
  <!-- Button trigger modal -->
  <button type="button" class="btn btn-sm btn-light-warning font-weight-bold" data-bs-toggle="modal" :data-bs-target="'#exampleModal'+id.uid">
    <i class="fs-1-3 la la-edit"></i>
  </button>
  <!-- Modal -->
  <div class="modal fade" :id="'exampleModal'+id.uid" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Edit Transport Cost</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row mb-3" v-show="success">
            <div class="col-12">
              <div class="alert alert-success" role="alert">
                Successfully changed transport cost!
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12">
              <label class="col-form-label required fw-bold fs-6">Location</label>
              <select class="form-select" :class="{'is-invalid' : errors.value && errors.value.location_id}" v-model="myTransportCost.value.location_id" @change="myTransportCost.value.location_id = $event.target.value">
                <option selected disabled value="null">Select Location</option>
                <option :value="item.id" v-for="(item,key) in store.getters.myLocations" :key="key">{{item.name}}</option>
              </select>
              <span class="text-danger" v-if="errors.value && errors.value.location_id">{{errors.value && errors.value.location_id[0]}}</span>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12">
              <label class="col-form-label required fw-bold fs-6">Cost</label>
              <input type="number" class="form-control" :class="{'is-invalid' : errors.value && errors.value.cost}" v-model="myTransportCost.value.cost">
              <span class="text-danger" v-if="errors.value && errors.value.cost">{{errors.value && errors.value.cost[0]}}</span>
            </div>
          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" :id="'close-modal'+id.uid">Close</button>
          <button
            type="submit"
            :id="'submitButton'+id.uid"
            class="btn btn-primary fw-bolder"
            @click="submitForm();"
          >
            <span class="indicator-label">
              Save
            </span>

            <span class="indicator-progress">
              Please wait...
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script lang="ts">
import { Actions } from "@/store/enums/SampleTransportCostsEnums.ts";
import { useStore } from 'vuex'
import { ref, reactive, onMounted, getCurrentInstance } from 'vue'
 export default {
   name : 'EditSampleTransportCost',
   props : {
     transportcost : {
       type : [Array,Object]
     },
   },
   emits: ['input'],
   setup(props, { emit }){
     const store = useStore();

     const id = getCurrentInstance() as any;

     const myTransportCost = reactive(JSON.parse(JSON.stringify({ value : props.transportcost})));

     const success = ref(false);

     const errors = reactive({
       value : {}
     });


     onMounted(() => {

       const myModalEl = document.getElementById('exampleModal'+id.uid) as HTMLElement;

       myModalEl.addEventListener('hidden.bs.modal', function () {
         success.value = false;
         myTransportCost.value = JSON.parse(JSON.stringify(props.transportcost))
       })

     })


     const submitForm = () => {

       const submitButton = ref<HTMLElement | null>(document.getElementById('submitButton'+id.uid) as HTMLElement);

       if(submitButton.value){
         submitButton.value.setAttribute("data-kt-indicator", "on");
       }

       store.dispatch(Actions.UPDATE_SAMPLE_TRANSPORT_COST,myTransportCost.value).then(() => {

         errors.value = [];
         success.value = true;

         setTimeout(function(){
           submitButton.value?.removeAttribute("data-kt-indicator");
           const closeModal = document.getElementById('close-modal'+id.uid) as HTMLElement;
           closeModal.click();
           emit('input')
         },1000)

       }).catch((response) => {
         submitButton.value?.removeAttribute("data-kt-indicator");
         errors.value = response.data.errors;
         setTimeout(function(){
           const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
           input.focus();
         },100)

       })
     }

     return {
       id,
       props,
       errors,
       store,
       submitForm,
       myTransportCost,
       success
     }
   }
 }
</script>
