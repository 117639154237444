
import { reactive, ref, onMounted } from 'vue'
import { useStore } from "vuex";
import { Actions } from "@/store/enums/SamplesEnums.ts";
import { useRouter } from "vue-router";
import { Actions as StoreActions } from "@/store/enums/StoreEnums.ts";
export default {
  name : 'CompositeDetails',
  props : ["id"],
  setup(props){

    const store = useStore();
    const router = useRouter();

    const success = ref(false);

    store.dispatch(StoreActions.ADD_BODY_CLASSNAME,'page-loading')

    onMounted(() => {
      store.dispatch(Actions.GET_SAMPLE ,{id : props.id}).then(() => {

          store.dispatch(StoreActions.REMOVE_BODY_CLASSNAME,'page-loading')

      })
      
    //   store.dispatch(CurrencyActions.GET_CURRENCIES).then(() => {

    //     store.dispatch(StoreActions.REMOVE_BODY_CLASSNAME,'page-loading')

    //   });
    });


    const submitButton = ref<HTMLElement | null>(null);

    const errors = reactive({
      value : {}
    });


    const submitForm = () => {
      if(submitButton.value){
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      store.dispatch(Actions.UPDATE_SAMPLE,store.getters.currentSample).then(() => {
        errors.value = [];
        success.value = true;
        window.scrollTo(0,0);
        submitButton.value?.removeAttribute("data-kt-indicator");
        setTimeout(() => {
          router.push('/samples/list')
        },1000)

      }).catch((response) => {
        setTimeout(function(){
          const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
          input.focus();
        },100)

        errors.value = response.data.errors;

        submitButton.value?.removeAttribute("data-kt-indicator");

      })
    }

    return {
      success,
      store,
      submitButton,
      submitForm,
      errors
    }
  }
}
